<template>
  <div>
    <v-form ref="form"
            @submit.prevent="submit">
      <v-text-field v-model="dataObject.order"
                    label="order"
                    clearable/>
      <v-text-field v-model="dataObject.titleUz"
                    label="titleUz"
                    clearable/>
      <v-text-field v-model="dataObject.titleRu"
                    label="titleRu"
                    clearable/>
      <v-text-field v-model="dataObject.titleEn"
                    label="titleEn"
                    clearable/>

      <v-text-field v-model="dataObject.descriptionUz"
                    label="descriptionUz"
                    clearable/>
      <v-text-field v-model="dataObject.descriptionRu"
                    label="descriptionRu"
                    clearable/>
      <v-text-field v-model="dataObject.descriptionEn"
                    label="descriptionEn"
                    clearable/>

      <p>Content UZ</p>
      <ckeditor
          v-model="dataObject.contentUz"
          :editor="editor"
          :config="editorConfig" />

      <p>Content RU</p>
      <ckeditor
          v-model="dataObject.contentRu"
          :editor="editor"
          :config="editorConfig" />

      <p>Content EN</p>
      <ckeditor
          v-model="dataObject.contentEn"
          :editor="editor"
          :config="editorConfig" />

      <v-file-input v-model="dataObject.bigImg"
                    accept="image/*"
                    label="bigImg"
      />
      <v-file-input v-model="dataObject.smallImg"
                    accept="image/png"
                    label="smallImg"
      />
      <v-row no-gutters>
        <v-spacer/>
        <v-btn @click="closeModal" color="primary" text class="mr-3">Cancel</v-btn>
        <v-btn type="submit" color="primary" depressed>Save</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {baseURL} from "@/services/api";
import {createFormData} from "@/services/validationRules";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import TokenService from "@/services/token";
export default {
  props: {
    dataObject: Object
  },
  name: "Form",
  data: () => ({
    editor: Editor,
    editorConfig: {
      simpleUpload: {
        uploadUrl: baseURL + "image/upload",
        withCredentials: false,
        headers: {
          Authorization: `${TokenService.getToken()}`
        }
      }
    }
    // dataRules: {
    //     titleUz: [
    //         v => !!v || 'Amount is required',
    //         v => (v && v >= 0) || 'Minimum amount is 1',
    //     ],
    // }
    // editorSettings: {
    //   modules: {
    //     imageDrop: true,
    //     table: true,
    //     imageResize: {
    //       modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
    //       displaySize: true,
    //       handleStyles: {
    //         backgroundColor: 'black',
    //         border: 'none',
    //         borderRadius: '6px'
    //       }
    //     }
    //   }
    // }
  }),
  methods: {
    submit() {
      this.$emit('send-service', createFormData(this.dataObject))
    },
    closeModal() {
      this.$emit('close-modal');
    }
  },
}
</script>

<style scoped>

</style>